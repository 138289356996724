<template>
  <div class="pt-5 px-6">
    <div class="flex items-center flex-wrap">
      <h1 class="text-2xl flex-grow">
        <QuestionCircleOutlined />
        Need a help?
      </h1>
    </div>
    <p class="text-gray-400 text-base">We are here to support you ASAP</p>
  </div>

  <div class="bg-gray-200 px-6 py-2 flex items-center">
    <div class="flex-grow text-gray-400">To get help, please contact us via a below email</div>
  </div>

  <div class="m-6">
    <a-alert type="info" showIcon message="info@sango-tech.com"> </a-alert>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { QuestionCircleOutlined } from '@ant-design/icons-vue'

@Options({
  components: { QuestionCircleOutlined },
  directives: { maska },
})
export default class Help extends Vue {}
</script>
