
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { QuestionCircleOutlined } from '@ant-design/icons-vue'

@Options({
  components: { QuestionCircleOutlined },
  directives: { maska },
})
export default class Help extends Vue {}
